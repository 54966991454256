<template>
  <div
    class="
      dis_flex
      flex_dir_col
      bg_col_fff
      bor_rad_4
      box_sha_0_0_8_8_black_80_per
      m_0_16
    "
  >
    <div class="dis_flex ali_it_cen m_0_12 m_t_16">
      <div class="flex_1 font_16 font_bold">审批统计</div>

      <!-- 选项卡 -->
      <div class="dis_flex ali_it_cen">
        <img
          class="w_16 h_16 dis_flex m_r_8"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"
        />

        <!-- 项 -->
        <div v-for="(item, index) in tabArr" :key="index" class="font_14">
          <span v-show="index > 0" class="m_0_8">/</span>
          <span
            :class="`${tabIndex == index ? 'col_2396F5' : ''}`"
            @click="
              tabIndex = index;
              filterForm = {};
              getList(true);
            "
            >{{ item.label }}</span
          >
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 项 -->
    <div
      v-for="(item, index) in list"
      :key="index"
      class="dis_flex ali_it_cen m_0_12 m_t_16"
    >
      <!-- 图标 -->
      <img class="w_32 dis_flex" :src="item.iconUrl" />

      <div class="w_24"></div>

      <!-- 属性 -->
      <div v-for="(item1, index1) in item.values" :key="index1" class="flex_1">
        <div class="font_16 font_bold l_h_100_per">{{ item1.value }}</div>
        <div class="font_12 col_666 l_h_100_per m_t_8">
          {{ item1.label }}
        </div>
      </div>

      <!--  -->
    </div>

    <div class="h_16"></div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import { brokerDashboardApprovalStatisticsPost } from '@/api/index';

const defaultFilterForm = () => {
  return {};
};

export default {
  components: {},
  data() {
    return {
      tabIndex: 0,
      filterForm: { ...defaultFilterForm() },
      list: [],

      //
    };
  },
  computed: {
    // 选项卡
    tabArr: {
      get() {
        return [
          {
            label: '当日',
            value: 'CURRENT_DAY',
          },
          { label: '本周', value: 'CURRENT_WEEK' },
          { label: '本月', value: 'CURRENT_MONTH' },
          { label: '上月', value: 'LAST_MONTH' },
        ];
      },
    },

    //
  },
  mounted() {
    this.init();

    //
  },
  methods: {
    columnAmount,

    // 获取
    async getList() {
      const list = await brokerDashboardApprovalStatisticsPost({
        duration: this.tabArr[this.tabIndex].value,
      });
      this.list = list.map((item, index) => {
        return {
          iconUrl:
            index == 1
              ? 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/JUBgZQeTcLSwovLhohfd.svg'
              : index == 2
              ? 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/pdfGphHLDUIWRFLPgXOG.svg'
              : index == 3
              ? 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/gbOIvHGPbDJNZMLMlDHx.svg'
              : 'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/vUcCvpBzLkLZedddqRPl.svg',
          values: [
            {
              label: item.title2,
              value: columnAmount(item.count, { fixed: 0 }),
            },
            {
              label: item.title1,
              value: columnAmount((item.amount || 0) / 10000),
            },
          ],
        };
      });

      //
    },

    // 初始化
    async init() {
      await this.getList();

      //
    },

    //
  },
};
</script>
